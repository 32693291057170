// extracted by mini-css-extract-plugin
export var accountType = "signup-module--accountType--8e350";
export var accountTypeOp = "signup-module--accountTypeOp--f73a6";
export var createAccountTitle = "signup-module--createAccountTitle--8d134";
export var formSignUp = "signup-module--formSignUp--dc129";
export var gridForm = "signup-module--gridForm--da108";
export var gridPass = "signup-module--gridPass--bae0d";
export var gridSignup = "signup-module--gridSignup--652b0";
export var isOpen = "signup-module--isOpen--4a009";
export var root = "signup-module--root--2e0c1";
export var typeLabel = "signup-module--typeLabel--8794e";
export var typeRadio = "signup-module--typeRadio--bebc5";